<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="{ name: 'result-create', params: { quizId } }">
        <CButton color="success"> Создать результат </CButton>
      </router-link>
      <br />
      <br />
      <CDataTable
        :items="results"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter="{ label: 'Поиск', placeholder: 'найти...' }"
      >
        <template #lead="{ item }">
          <td>
            <router-link
              :to="{ name: 'result-edit', params: { quizId, id: item.id } }"
            >
              {{ item.lead }}
            </router-link>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}
.c-card-body {
  background-color: #fefefe;
}
table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}
.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
tr:hover .td {
  background: #e8edff;
}
.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}
.item-right {
  text-align: right;
}
</style>

<script>
import { mapActions } from "vuex";
const fields = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "lead",
    label: "Лид",
  },
];
export default {
  name: "QuizResultsList",
  data() {
    return {
      fields,
      quizId: this.$route.params.quizId,
      id: this.$route.params.id,
      results: [],
    };
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "results/getByQuizIdSuccess") {
        this.results = mutation.payload.results;
      }
    });
    this["results/getByQuizId"](this.quizId);
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions(["results/getByQuizId"])
  },
};
</script>
